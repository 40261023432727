// FIXME: Yes, this is cheating
@import '../../../libs/bootstrap5-theme/src/bootstrap5-theme.scss';

// NOTE: Harmful; BREAKS SCROLL MANAGEMENT BEHAVIOR from next.js
// html,
// body,
// #__next {
//   display: block;
//   margin: 0;
//   padding: 0;
//   width: 100vw;
//   height: 100vh;
//   box-sizing: border-box;
//   overflow-y: auto;
//   overflow-x: hidden;
//   position: relative;
// }

// NOTE: ensures footer always at bottom of screen
#__next {
  //   min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
