// Extends existing Bootstrap mixin

@import '../../../node_modules/bootstrap5/scss/functions';
@import '../../../node_modules/bootstrap5/scss/variables';
@import '../../../node_modules/bootstrap5/scss/mixins';

// FIXME: Extract colour definitions
$summary-value-color: mix($body-color, $body-bg, 75%);

.list-summary,
.tree-summary,
.tree-breadcrumb {
  padding: $pagination-padding-y $pagination-padding-x;

  & > .value {
    font-weight: 600;
    color: $summary-value-color;
  }
}

.pagination {
  margin-bottom: 0;

  .page-item.disabled {
    span.page-link {
      cursor: default;
      background-color: theme-neutral-color('300');
    }
  }

  .page-link {
    min-width: 2.85em;
    text-align: center;

    &.page-numbered[disabled] {
      z-index: 3;
      color: theme-neutral-color('000');
      background-color: theme-abstract-color('primary');
      border-color: theme-abstract-color('primary');
    }

    &[disabled] {
      color: theme-neutral-color('600');
      // pointer-events: none;
      border-color: theme-neutral-color('200');
      background-color: theme-neutral-color('200');
    }

    .mdi {
      &::before {
        line-height: 0;
      }
    }
  }

  &-sm {
    .page-link {
      border-width: 1px;
    }
  }
}

.recordset-metadata {
  order: 20;
  // max-width: 100%;
  // margin: 0; // overrides .row
  flex: 0;
  // display: flex;
  // flex-direction: row;

  &:empty {
    height: 0;
    display: none;
  }

  // .pagination-wrapper,
  // .summary-wrapper {
  //   padding-left: 0; // overrides .col
  //   padding-right: 0; // overrides .col
  // }

  // .pagination {
  //   padding: 0.5rem 0;
  //   //width: 100%;

  //   &-wrapper {
  //     order: 20;
  //   }
  // }

  // .summary {

  //   &-wrapper {
  //     order: 10;
  //   }
  //   //width: 100%;
  // }
}

// .pagination {
//   float: right;

//   .page-item {
//     flex: 0 0 auto;
//     text-align: center;
//     text-transform: uppercase;

//     .page-link {
//       border-width: 2px;
//       margin-left: -2px;
//       min-width: 2.5rem;

//       &:hover {
//         z-index: 1;
//       }
//     }

//     &:not(.disabled) {

//       .page-link {
//         border-color: theme-neutral-color('400');
//         color: theme-neutral-color('800');

//         &:hover {
//           z-index: 1;
//         }
//       }
//     }

//     &.disabled {

//       .page-link {
//         color: theme-neutral-color('600');
//         border-color: theme-neutral-color('200');
//         background-color: theme-neutral-color('200');
//       }
//     }

//     &.active {

//       .page-link {
//         border-width: 2px;
//         color: theme-neutral-color('000');
//         border-color: theme-abstract-color('primary');
//         background-color: theme-abstract-color('primary');
//       }
//     }
//   }
// }

// mobile
@media (max-width: map_get($grid-breakpoints, 'md') - 1px) {
  // .pagination {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: stretch;

  //   .page-item {
  //     flex: 1 1 auto;
  //   }
  // }
}

// desktop
@media (min-width: map_get($grid-breakpoints, 'md')) {
  // nothing yet
}

// TODO: CSS-Modularize the whole component
// .pagination {
//   user-select: none;
//   font-weight: 600;
//   margin: 0;
//   padding: 0.5rem 0;
//   //padding: 1rem 0;

//   //.page-item {
//   //  flex: 1 1 0px;
//   //}

//   // mobile
//   @media (max-width: map_get($grid-breakpoints, 'md') - 1px) {

//     //display: flex;
//     //flex-direction: row;
//     //justify-content: stretch;

//     .page-item {
//       flex: 1 1 auto;
//     }
//   }

//   .disabled {
//     cursor: not-allowed;
//   }

//   .page-link:not(.disabled) {

//     &:focus,
//     &.focus {
//       outline-offset: -4px;
//     }
//   }

//   .page-first,
//   .page-last {

//     .page-link {
//       border-color: transparent;
//       //border-width: 0;
//       margin-left: 0;
//       border-radius: 1px;
//     }

//     a:hover {
//       background-color: transparent;
//     }
//   }

//   .page-previous {

//     .page-link {
//       // FIXME?
//       // @include border-left-radius($border-radius);
//     }
//   }

//   .page-next {

//     .page-link {
//       // FIXME?
//       // @include border-right-radius($border-radius);
//     }
//   }

//   .page-ellip {

//     .page-link {
//       padding-left: 0.5rem;
//       padding-right: 0.5rem;
//     }
//   }
// }

// .list-summary {
//   line-height: 1.25;
//   user-select: none;
//   padding: 1.6rem 0.75rem;
//   text-align: left;

//   & > .value {
//     font-weight: 600;
//     color: mix($body-color, $body-bg, 75%);
//     display: inline-block;
//   }
// }
