@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';
@import './bootstrap-variables';

.list-view {
  $list_border_color: map_get($colors, secondary06); // fixme

  flex: 1 1 0;
  overflow-y: auto;
  height: 100%;

  //width: 100%;
  //max-height: 100%;
  //display: flex;
  //flex-direction: row;
  //
  //transition: all 1s ease;

  & > .row {
    flex: 1 1 auto;
  }

  // Use flexbox to reposition filters as secondary importance
  &-aside {
    order: 0;
  }

  &-main {
    order: 1;

    .list-group {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      border-style: solid none;
      border-width: 1px;
      border-color: $list_border_color;

      .btn-group {
        white-space: nowrap;
        align-self: flex-start;
      }

      .btn {
        //white-space: nowrap;
        //overflow: hidden;
        //line-height: 1;
      }
    }
  }

  .action-list {
    // nothing yet
  }

  // fixme: icons broken
  //.action-icon {
  //  $icon-size: 1rem;
  //
  //  @extend .icon;
  //
  //  height: $icon-size;
  //  font-size: $icon-size;
  //  vertical-align: middle;
  //}
}

.compact-list {
  .list-group {
    .list-summary-item {
      display: flex;
      //flex-direction: row;

      .summary {
        &-icon {
          transform: scale(0.85);
        }

        &-title {
          flex: 1 1 auto;
          font-size: 1rem;
        }

        &-text {
          display: none;
        }

        &-fields {
          line-height: 1.5rem;
          margin: 0;
          padding: 0;

          .field-pair {
            //align-content: flex-end;
            justify-content: flex-end;
            justify-items: flex-end;
            font-size: 0.9em; // relative size is purposeful, symmetry with elsewhere
            flex-wrap: wrap;

            &:first-of-type {
              // Nothing; this is the sole one we want to display
            }

            &:not(:first-of-type) {
              opacity: 0;
              display: none; // maybe visibility: hidden instead?
            }

            .field-key {
              //flex: 0 1 auto;
            }

            .field-value {
              padding-right: 0.5rem;
            }
          }
        }
      }
    }

    // mobile
    @media (max-width: map_get($grid-breakpoints, 'md') - 1px) {
      .list-summary-item {
        flex-direction: column;
      }
    }

    // desktop
    @media (min-width: map_get($grid-breakpoints, 'md')) {
      .list-summary-item {
        flex-direction: row;
      }

      .summary {
        &-icon {
        }

        &-title {
          flex: 1 1 auto;
          display: flex;
        }

        &-fields {
          flex: 0 1 auto;
          display: inline;
          white-space: nowrap;
          align-self: flex-end;

          .field-pair {
          }

          .field-key {
            align-content: flex-end;
            text-align: right;
            min-width: 0;
          }

          .field-value {
            align-content: flex-end;
            text-align: right;
          }
        }
      }
    }
  }
}

.list-summary-item {
  padding: 0.5rem 15px;

  & > * {
    $transition-time: 1s;
    transition: font-size $transition-time ease, opacity $transition-time ease,
      margin $transition-time ease, flex $transition-time ease,
      padding $transition-time ease;
  }

  .summary {
    &-key {
    }

    &-icon {
      position: absolute;
      line-height: 1.75rem;

      & > i,
      & > .fa,
      & > .fas {
        vertical-align: baseline;
        color: theme-neutral-color('600');
      }
    }

    &-title {
      font-size: 1.15rem;
      line-height: 1.75rem;
      font-weight: 600;
      padding: 0;
      margin: 0;
      vertical-align: bottom;
    }

    &-instructions {
      font-size: 90%;
      opacity: 0.75;
    }

    &-text {
      // todo ...
      flex-basis: auto;
    }
  }

  a {
    &:hover {
      border-style: none;
    }
  }

  // The item is active (selected etc.)
  &.active {
    $active_bg: theme-abstract-color('primary');

    background-color: $active_bg;
    color: theme-neutral-color('000');

    .summary {
      &-icon {
        color: theme-neutral-color('400');
      }

      &-title {
        color: theme-neutral-color('000');
      }

      &-text {
        color: theme-neutral-color('000');
      }

      &-fields {
        .field-key {
          $key_bg: theme-neutral-color('900');

          background-color: mix($active_bg, $key_bg, 25%);
          color: theme-neutral-color('100');
        }

        .field-value {
          color: theme-neutral-color('100');
        }
      }
    }

    & + .active {
      border-top: 1px solid theme-abstract-color('primaryToneDark');
    }
  }

  // The item is disabled (non-interactive)
  &.disabled {
    .summary {
      &-icon {
        opacity: 0.7;
      }

      &-title {
      }

      &-text {
      }

      &-fields {
        opacity: 0.7;

        .field-pair {
        }

        .field-key {
        }

        .field-value {
        }
      }
    }
  }

  // mobile
  @media (max-width: map_get($grid-breakpoints, 'md') - 1px) {
    padding: 0.5rem 10px;

    &.list-summary-offset {
      padding-left: 25px;
    }

    .summary {
      &-icon {
        left: 10px;
      }

      &-title {
        margin: 0 0.5rem;
      }

      &-text {
        margin: 0 0.5rem;
      }

      &-fields {
        .field-pair {
          flex: 1 1 100%;
          border-top-style: none;
        }

        .field-key {
          flex: 0 0 33.3%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .flex-value {
          flex: 0 0 66.6%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  // desktop
  @media (min-width: map_get($grid-breakpoints, 'md')) {
    &.list-summary-offset {
      padding-left: 36px;
    }

    .summary {
      &-icon {
        left: 12px;
      }

      &-title {
        margin: 0 0.5rem;
      }

      &-text {
        margin: 0 0.5rem;
      }

      &-fields {
        .field-pair {
        }

        .field-key {
        }

        .field-value {
        }
      }
    }
  }
}
