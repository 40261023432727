@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

// TODO: Why is this here?
@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.slide-in {
  animation-name: slideIn;
}

.dropdown-menu {
  box-shadow: $theme-popup-box-shadow;
}
