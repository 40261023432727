@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';
@import './bootstrap-variables';

$secondary-color: $gray-700;
$secondary-bg-color: $white;
$secondary-border-color: $gray-500;

$focus-secondary-color: $gray-800;
$focus-secondary-bg-color: $gray-300;
$focus-secondary-border-color: $secondary-border-color;

$active-secondary-color: $gray-900;
$active-secondary-bg-color: $gray-500;
$active-secondary-border-color: $gray-500;

.btn {
  text-transform: uppercase;

  &.no-text-transform {
    text-transform: none;
  }

  &:focus {
    // Override the complex but subtle-colored defaults
    box-shadow: $theme-focus-box-shadow !important;
  }
}

.btn-circle {
  border-radius: 50%;
  padding: $input-btn-padding-y $input-btn-padding-y;
}

.btn-secondary {
  color: $secondary-color;
  background-color: $secondary-bg-color;
  border-color: $secondary-border-color;

  &:disabled,
  &.disabled {
    color: $secondary-color;
    background-color: $secondary-bg-color;
    border-color: $secondary-border-color;
  }

  &:hover,
  &:focus {
    color: $focus-secondary-color;
    background-color: $focus-secondary-bg-color;
    border-color: $focus-secondary-border-color;
  }
}

.btn.btn-secondary:active,
.btn.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: $active-secondary-color;
  background-color: $active-secondary-bg-color;
  border-color: $active-secondary-border-color;
  // box-shadow: none;
}

.btn.btn-outline-secondary {
  color: $secondary-color;
  // background-color: $secondary-bg-color;
  border-color: $secondary-border-color;

  &:disabled,
  &.disabled {
    color: $secondary-color;
    // background-color: $secondary-bg-color;
    border-color: $secondary-border-color;
  }

  &:hover,
  &:focus {
    color: $focus-secondary-color;
    background-color: $focus-secondary-bg-color;
    border-color: $focus-secondary-border-color;
  }
}

.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: $active-secondary-color;
  background-color: $active-secondary-bg-color;
  border-color: $active-secondary-border-color;
}

// TODO: colored btn link with function map; for now behave like a link
.btn-link {
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn-sm {
  border-width: 1px;

  .btn-circle {
    padding: $input-btn-padding-y-sm $input-btn-padding-y-sm;
  }
}

.btn.btn-close:active,
.btn.btn-close.active {
  // Stupid upstream definition sets no background image
  background-image: $btn-close-bg;
}
