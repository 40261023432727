@charset "UTF-8";
@use 'sass:math';

@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 12px !default;
$hamburger-padding-y: 10px !default;
$hamburger-layer-width: 18px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 3px !default;
$hamburger-layer-color: theme-neutral-color('600');
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: theme-abstract-color('primaryToneDarker');
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================
.hamburger {
  //padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.3s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: math.div($hamburger-layer-height, -2);

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: calc((#{$hamburger-layer-spacing} + #{$hamburger-layer-height}) * -1);
  }

  &::after {
    bottom: calc((#{$hamburger-layer-spacing} + #{$hamburger-layer-height}) * -1);
  }
}

// Animations
// ==================================================
/*
* Arrow Right
*/
.hamburger-arrow-right.is-active {
  .hamburger-inner {
    &::before {
      transform: translate3d($hamburger-layer-width * 0.225, 1px, 0) rotate(45deg)
        scale(0.6, 1);
    }

    &::after {
      transform: translate3d($hamburger-layer-width * 0.225, -1px, 0) rotate(-45deg)
        scale(0.6, 1);
    }
  }
}

/*
* Arrow Turn
*/
.hamburger-arrow-rotate.is-active {
  .hamburger-inner {
    transform: rotate(-180deg);

    &::before {
      transform: translate3d($hamburger-layer-width * 0.225, 1px, 0) rotate(45deg)
        scale(0.6, 1);
    }

    &::after {
      transform: translate3d($hamburger-layer-width * 0.225, -1px, 0) rotate(-45deg)
        scale(0.6, 1);
    }
  }
}
