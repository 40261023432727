@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

.body-wrapper {
  // Placeholder; no definitions yet
}

.nav-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;

  nav[orientation='vertical'] ul {
    padding: 8px 0;
    width: 100%;
  }
}

.main-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow: auto;
}
