@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

.filterset-toggle {
  &.active {
    .badge {
      opacity: 0;
    }
  }

  .badge {
    opacity: 1;
    transition: opacity 250ms;
  }
}

.filter-form {
  .form-radio-label,
  .form-check-label {
    &:hover {
      color: $link-color;
    }
  }
}

// NOTE: This approach may be required in Bootstrap 4, but in
// BS 5 its dependency is eliminated
.filter-set {
  //overflow: hidden; // combine with `.filters {overflow=auto}`

  .titlebar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px 15px;

    .card-title {
      color: theme-neutral-color('800');
      flex: 1 1 auto;
      //font-size: 1.15rem;
      font-size: 1em;
      font-weight: 600;
      margin: 0;
      padding: 0.65rem 0;
      align-self: flex-start;
    }
  }

  .filters {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;

    overflow: auto; // needs overflow hidden on card
  }

  .filter {
    &-form {
      margin: 0;
      padding: 0;
      //color: theme-neutral-color('900');
      //background-color: theme-neutral-color('100');
    }

    &-list {
    }

    &-group {
      &-title {
        display: block;
        width: 100%;
        padding: 8px 14px;
        font-weight: 600;
        cursor: pointer;
        border: none;
        border-radius: 0;
        text-align: left;
        color: theme-neutral-color('700');
        background-color: theme-neutral-color('100');

        &:not(:first-child) {
          border-top: 1px solid theme-neutral-color('200');
        }

        &:hover {
          color: theme-neutral-color('900');
        }

        &:focus {
          // outline-offset: -2px;
          // outline: 2px dotted theme-abstract-color('focus');
          color: theme-neutral-color('900');
        }

        .icon {
          font-size: 70%;
          padding: 7px 0;
          color: theme-neutral-color('600');
          transition: all 0.25s ease-in;
        }

        .badge {
          font-size: 85%;
          padding: 0.3em 0.4em;
          margin: 0.2em;
        }

        &.collapsed {
          .icon {
            transform: rotate(-90deg);
          }
        }
      }

      &-content {
        padding: 0 14px 0 14px;

        &.collapsing {
          transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
        }

        .form-group:first-child {
          margin-top: 14px;
        }

        // This contrived class butts multiple fields together vertically
        .input-stack {
          .input-group-text {
            background-color: theme-neutral-color('100');
            color: theme-neutral-color('600');
            text-transform: uppercase;
            font-size: 0.9em;
            font-weight: 600;
            width: 6em;
            justify-content: flex-end;
          }

          .input-group-text,
          input[type='text'],
          input[type='date'] {
            border-bottom-style: none;
            margin: 0;
            border-radius: 0;
            line-height: 1.1;
          }

          .stack-top {
            .input-group-text {
              border-top-left-radius: $input-border-radius;
            }

            input[type='text'],
            input[type='date'] {
              border-top-right-radius: $input-border-radius;
            }
          }

          .stack-bottom {
            .input-group-text {
              border-top-style: dotted;
              border-top-color: rgba($input-border-color, 0.65);
              border-bottom-left-radius: $input-border-radius;
              border-bottom-style: solid;
            }

            input[type='text'],
            input[type='date'] {
              border-top-color: rgba($input-border-color, 0.65);
              border-top-style: dotted;
              border-bottom-style: solid;
              border-bottom-right-radius: $input-border-radius;
            }
          }
        }
      }
    }
  }
}
