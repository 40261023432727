@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

// The default is too light to be useful
.border-light {
  border-color: $gray-300 !important;
}

// Keep default padding, otherwise text moves during animation.
.mark {
  padding: inherit !important;
}
