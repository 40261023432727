.login-graphic,
.login-graphic:before,
.login-graphic:after {
  content: " ";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.login-graphic {
  &.login-bg-dark {
    background-color: #00263e;
    background-image: radial-gradient(circle at 33% 66%, #07151c, #00263e);
  }

  &.login-bg-orange {
    background-color: #fc4c02;
    background-image: radial-gradient(
      circle at 33% 66%,
      rgb(230, 69, 0),
      #fc4c02
    );
  }

  &.login-bg-light {
    background-color: #f2f2f2;
    background-image: radial-gradient(circle at 33% 66%, #ffffff, #f2f2f2);
  }
}
