@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

$closed-width: var(--closed-width, 64px);
$open-width: var(--open-width, 260px);
$tx-duration: 250ms;
$tx-timing-func: ease-in-out;
$feature-nav-bgcolor: theme-neutral-color('100');
$feature-nav-border-color: theme-neutral-color('300');

$feature-link-textcolor: theme-neutral-color('800');
$feature-link-iconcolor: theme-neutral-color('700');
$feature-link-textcolor-hover: theme-abstract-color('primary');
$feature-link-bgcolor-hover: rgba(theme-abstract-color('focus'), 0.15);
$feature-link-bgcolor-active: theme-neutral-color('000');
$feature-link-textcolor-active: theme-abstract-color('primary');
$feature-link-bordercolor-active: theme-abstract-color('primary');
$feature-link-iconcolor-active: theme-abstract-color('primary');

$feature-menu-hamburger-color: theme-neutral-color('600');
$feature-menu-toggle-bgcolor: mix(
  theme-neutral-color('200'),
  theme-neutral-color('300'),
  50%
);
$feature-menu-toggle-textcolor: theme-neutral-color('600');
$feature-menu-toggle-bordercolor: theme-neutral-color('400');
$feature-menu-toggle-bgcolor-active: theme-neutral-color('000');
$feature-menu-toggle-textcolor-active: theme-abstract-color('primaryTintDark');

.feature-menu-wrapper {
  max-width: $closed-width;
  display: flex;
  flex-direction: column;
  user-select: none;
  transition: min-width $tx-duration, max-width $tx-duration,
    opacity $tx-duration;
  transition-timing-function: $tx-timing-func;

  &.is-open {
    max-width: $open-width;

    .feature-title,
    .menu-title {
      min-width: calc(#{$open-width} - #{$closed-width});
      opacity: 1;
    }
  }
}

.feature-menu-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: stretch;
  flex: 1 1 100%;
  background-color: $feature-nav-bgcolor;
  border-right: 2px solid $feature-nav-border-color;
  overflow-x: hidden;
  overflow-y: auto;
}

.feature-menu-link {
  flex: 0 0 auto;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 0.95rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  min-width: 26px;
  padding: 0 9px 0 7px;
  text-decoration: none;
  // border-right: 2px solid #DFDFDF;
  // margin-right: -2px;
  transition-duration: $tx-duration;
  transition-timing-function: $tx-timing-func;
  transition-property: min-width, max-width, width, opacity, margin, padding,
    background-color, color;

  &[disabled],
  &.disabled {
    pointer-events: none;
    color: #bfbfbf;
  }

  &:not(.disabled),
  &:not([disabled]) {
    color: $feature-link-textcolor;

    .FeatureIcon {
      color: $feature-link-iconcolor;
    }

    &:focus.focus,
    &:focus:focus {
      outline-offset: -2px;
    }

    &:hover {
      transition-delay: calc(-#{$tx-duration} * 0.5);
      text-decoration: none;
      color: $feature-link-textcolor-hover;
      background-color: $feature-link-bgcolor-hover;

      .FeatureIcon {
        opacity: 1;
      }
    }
  }

  &:active,
  &.active {
    background-color: $feature-link-bgcolor-active;
    color: $feature-link-textcolor-active;
    border-right: 4px solid $feature-link-bordercolor-active;
    padding-right: 5px;

    .FeatureIcon {
      color: $feature-link-iconcolor-active;
      opacity: 1;
    }
  }
}

.feature-icon {
  padding: 8px;
  width: 36px;
  height: 36px;
  margin: 2px 7px 2px 5px;
  position: relative;
  top: 2px;
  opacity: 0.75;
  transition-duration: $tx-duration;
  transition-timing-function: $tx-timing-func;
}

.feature-title,
.menu-title {
  // display: inline-block;
  display: block;
  padding: 0.5rem 0;
  overflow: hidden;
  flex: 1 1 100%;
  transition: min-width $tx-duration, max-width $tx-duration, width $tx-duration,
    opacity $tx-duration;
  transition-timing-function: $tx-timing-func;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 1.875;
  text-overflow: ellipsis;

  // TODO: Animate parameters depending on 'open' status
  min-width: 0;
  opacity: 0;
}

.feature-menu-wrapper.is-open {
  max-width: $open-width;

  .FeatureTitle,
  .MenuTitle {
    min-width: calc(#{$open-width} - #{$closed-width});
    opacity: 1;
  }
}

.feature-menu-toggle {
  vertical-align: middle;
  flex: 0;
  font-size: 1rem;
  flex: 0 0 auto;
  // text-transform: uppercase;
  font-size: 0.935rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  transition: min-width $tx-duration, max-width $tx-duration, width $tx-duration,
    opacity $tx-duration;
  transition-timing-function: $tx-timing-func;
  min-width: 26px;

  // padding: 8px 16px;
  padding: 6px 10px 3px 10px;
  background-color: $feature-menu-toggle-bgcolor;
  color: $feature-menu-toggle-textcolor;
  font-weight: 700;
  border-right: 2px solid $feature-menu-toggle-bordercolor;
}

.toggle-button.btn {
  flex: 0 0 36px;
  padding: 0;
  width: 36px;
  height: 36px;
  margin: 3px 5px 3px 4px;
  border-radius: 100%;
  border-style: none;
  outline-style: none;
  transition: background-color $tx-duration;
  transition-timing-function: $tx-timing-func;

  .hamburger-box > * {
    color: $feature-menu-hamburger-color;
  }

  &.active,
  &:active,
  &:hover {
    // border: none;
    // outline: none;
    background-color: $feature-menu-toggle-bgcolor-active;
    color: $feature-menu-toggle-textcolor-active;
  }
}

@import './hamburger';

// LEGACY
/*
#penfold-app-nav {
  min-width: 68px;
  //max-width: 276px; // I don't think this is needed ...
  display: flex;
  flex-direction: column;
  transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  .nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: stretch;

    flex: 1 1 100%;
    background-color: theme-neutral-color('100');
    border-right: 2px solid theme-neutral-color('300');
  }

  .menu-toggle,
  .nav-link {
    flex: 0 0 auto;
    //text-transform: uppercase;
    //font-size: 1.07rem;
    font-size: 0.935rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
      width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    min-width: 26px;
  }

  .menu-toggle {
    padding: 6px 10px 3px 10px;
    background-color: $menu-toggle-bgcolor;
    color: theme-neutral-color('600');
    font-weight: 700;
    border-right: 2px solid theme-neutral-color('400');
  }

  .nav-link {
    font-weight: 600;
    padding: 0 8px 0 10px; // right-padding minus border
    border-right: 2px solid theme-neutral-color('400');
    margin-right: -2px;
    transition: margin-right 0.25s ease-in-out, padding-right 0.25s ease-in-out,
      background-color 0.25s ease-in-out, color 0.25s ease-in-out,
      border-style 0.25s ease-in-out, border-width 0.25s ease-in-out,
      border-color 0.25s ease-in-out;

    &:not([disabled]),
    &:not(.disabled) {
      color: theme-neutral-color('800');
    }

    &[disabled],
    &.disabled {
      color: theme-neutral-color('400');
    }

    &:active,
    &.active {
      background-color: theme-neutral-color('000');
      color: theme-abstract-color('primary');
      border-right: 5px solid theme-abstract-color('primary');
      padding-right: 5px; // as the border grows, reduce padding

      .nav-icon {
        opacity: 1;
      }
    }

    // &:focus {
    //   outline-color: theme-abstract-color('focus');
    //   outline-offset: -2px;
    // }
  }

  .nav-spacer {
    flex: 1 1 100%;
  }

  .btn.hamburger {
    width: 36px;
    height: 36px;
    margin: 3px 5px;
    padding: 0;
    border-style: none;
    outline-style: none;
    background-color: rgba(theme-neutral-color('000'), 0);
    transition: background-color 0.25s ease-in;

    &.active,
    &:active,
    &:hover {
      border: none;
      // outline: none;
      background-color: rgba(theme-neutral-color('000'), 1);
      color: theme-abstract-color('primaryToneDarker');
    }

    &:focus {
      // border: none;
      color: theme-abstract-color('primaryToneDarker');
      background-color: rgba(theme-neutral-color('100'), 1);
      // outline: 2px dotted theme-abstract-color('focus');
    }

    .hamburger-box > * {
      color: theme-neutral-color('600');
    }
  }

  .nav-icon {
    padding: 4px 8px;
    width: 36px;
    height: 34px;
    margin: 2px 5px;
    position: relative;
    top: 2px;
    opacity: 0.75;
    transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
  }

  .nav-text {
  }

  .nav-text,
  .menu-title {
    display: inline-block;
    padding: 0.5rem 0;
    overflow: hidden;
    flex: 1 1 100%;
    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
      width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    box-sizing: border-box;
    min-width: 0;
    max-width: 0;
    opacity: 0;
    white-space: nowrap;
    line-height: 1.7;
    text-overflow: ellipsis;
  }

  .menu-title {
  }
}

.wide-nav {
  #penfold-app-nav {
    //min-width: 277px;
    min-width: 260px;

    .menu-title {
      //min-width: 208px;
      min-width: 173px;
    }

    .nav-text,
    .menu-title {
      opacity: 1;
    }

    .nav-text {
      //min-width: 210px;
      min-width: 175px;
    }

    .active {
      //margin-right: 0;

      .nav-text {
        //min-width: 210px; // account for border
        min-width: 175px;
      }
    }
  }
}
*/
