@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

// FIXME: wrapping these isn't going to work, because we don't want to
// wrap the remaining items.
.instructions {
  font-size: 1rem;
  color: theme-abstract-color('primaryToneDark');
}
