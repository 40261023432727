@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

body {
  .body-container {
    // filter: none;
    // filter: blur(0);
    transition-property: opacity, background-color, backdrop-filter;
  }

  &.modal-open {
    .body-container {
      // filter: blur(3px);
    }
  }
}

// To get backgrounds to blur properly, override some styles
.modal-backdrop {
  background-color: rgba($black, 0);
  opacity: 1 !important;
  transition-property: background-filter, background-color;

  &.show {
    background-color: rgba($black, 0.6);
    backdrop-filter: blur(3px);
  }
}
