@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

.fw-medium {
  font-weight: 600 !important;
}

.narrative {
  font-family: theme-font('serif');
}

.expressive {
  font-weight: 200;
  color: $expressive-color;
}

h1,
.h1 {
  &.expressive {
    font-weight: 600;
    font-size: 3.2rem;
  }

  &.narrative {
    font-size: 2.133333rem;
  }
}

h2,
.h2 {
  &.expressive {
    font-size: 2.4rem;
  }

  &.narrative {
    font-size: 1.866666rem;
  }
}

h3,
.h3 {
  &.expressive {
    font-size: 1.86667rem;
  }

  &.narrative {
    font-size: 1.6rem;
  }
}

h4,
.h4 {
  &.expressive {
    font-size: 1.5rem;
  }

  &.narrative {
    font-size: 1.333333rem;
  }
}

h5,
.h5 {
  &.expressive {
    font-size: 1.33333rem;
  }

  &.narrative {
    font-size: 1.2rem;
  }
}

h6,
.h6 {
  font-weight: 700;
  text-transform: uppercase;

  &.expressive {
    font-size: 1.066667rem;
    font-weight: 700;
    text-transform: unset;
  }

  &.narrative {
    font-size: 0.8rem;
  }
}
