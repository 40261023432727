@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

$required_asterisk_color: theme-abstract-color('danger');

.input-group {
  .btn {
    border-width: 1px;
  }

  .btn-secondary {
    border-color: $input-border-color;
  }
}

fieldset legend,
fieldset div legend {
  float: left; /* weird, but important for now ... */
  font-size: 0.95rem;
  line-height: 1.65;
  // FIXME: commnent out hacks; these shouldn't be needed ...
  // margin: 0 -0.5rem;
  // width: calc(100% + 1rem);
  // padding: 0.5rem;
}

input[type='search'] {
  /* Hides the search input 'x' in MSIE */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* Hides the search input 'x' in Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.form-check {
  &:not(disabled),
  &:not(.disabled) {
    cursor: pointer;

    input,
    .form-check-label {
      cursor: pointer;
    }
  }
}

/* Manage required fields */

// label/field wrapper
.form-show-optional {
  .field-required {
    label {
      .is-required {
        @include visually-hidden;
      }
    }
  }

  .field-optional {
    label {
      .is-optional {
        opacity: 0.75;
        font-weight: 400;
      }
    }
  }
}

.form-show-required {
  .field-required {
    label {
      .is-required {
        color: $required_asterisk_color;
        display: inline-block;
        // width: 0;
        // overflow: hidden;
        vertical-align: text-bottom;
        position: relative;
        text-indent: -9999px;
        width: 1em;
        // display: none;

        &:before {
          font-weight: 400;
          text-indent: 9999px;
          display: inline-block;
          position: absolute;
          // overflow: visible;
          // right: 0px;
          content: '*';
          vertical-align: text-bottom;
          font-size: 1.45em;
          margin-left: 0.15rem;
        }
      }
    }
  }

  .field-optional {
    label {
      .is-optional {
        @include visually-hidden;
      }
    }
  }
}
