@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

$app-header-color: theme-neutral-color('800');
$app-header-bgcolor: mix(
  theme-neutral-color('300'),
  theme-neutral-color('200'),
  75%
);

.app-header {
  color: $app-header-color;
  background-color: $app-header-bgcolor;
}

.app-title {
  font-size: 1.35rem;
  line-height: 1.85;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  // margin: 0.25rem;
}

// #penfold-app-header {
//   $app-header-bgcolor: mix(
//     theme-neutral-color('200'),
//     theme-neutral-color('100'),
//     75%);
//   background-color: $app-header-bgcolor;
//   color: theme-neutral-color('900');
//   width: 100%;

//   .header-spacer {
//     flex: 1 1 auto;
//   }

//   .app-toolbar {
//     margin: 0 18px;
//   }
// }

// #penfold-app-title {
//   font-size: 1.35rem;
//   line-height: 1.5;
//   font-weight: 400;
//   //max-width: 16em;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   padding: 15px 24px;
//   margin: 0;
// }
