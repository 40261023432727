@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

ul.tree {
  //position: relative;

  // Descendents of the parent `.tree-node`
  &.tree-children {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &.node-tree,
  .node-tree {
    //position: relative;
    max-width: 100%;
    // overflow: hidden;
    // overflow-x: hidden;

    &-branch {
    }

    &-leaf {
    }

    .node-content {
      //position: relative;
      // Do not touch the left-padding here
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: flex-start;
      // FIXME: use actual list-group-item separator variable
      border-bottom: 1px solid rgba(theme-neutral-color('900'), 0.15);

      .node-toggle,
      .node-toggle-placeholder {
        width: 2rem;
        height: 2rem;
        padding: 0;
        margin: 0.3rem 0.5rem 0.3rem;
        // width: 32px;
        // height: 32px;
        // margin: 2px 0 3px;
        padding: 0;
        position: relative;
        // flex: 0 0 32px;
        flex: 0 0 2rem;
      }

      .node-toggle {
        border-radius: 100%;
        border-color: transparent;
        position: relative;
        background-color: transparent;
        color: inherit;

        &:hover,
        &:focus,
        &:active {
          background-color: rgba(theme-neutral-color('900'), 0.2); // FIXME
          border-color: transparent;
          outline-offset: 1px;
        }

        .mdi {
          display: block;
          font-size: 1.75rem;
          line-height: 0;
          // color: theme-neutral-color('900'); // FIXME

          transition: transform 250ms ease-in-out;
        }

        .node-toggle-icon {
          transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
          color: theme-neutral-color('600');
          width: 100%;
          height: 100%;

          &.spinner {
            pointer-events: none;
            user-select: none;
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            opacity: 0;
          }
        }

        &.loading {
          .node-toggle-icon {
            &.spinner {
              opacity: 1;
            }
          }
        }

        &.collapsed {
          .mdi,
          .fa {
            transform: rotate(-90deg);
          }
        }
      }

      .node-status {
        &:focus {
          outline-offset: -2px;
        }
      }
    }
  }

  // The root node(s) of a tree; comes after so it has some level of precedence
  &.tree-root {
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1 1 100%;
    overflow-y: visible;
    min-height: 300px;
    max-height: 100%;
  }
}

.node-status {
  // FIXME: Should use variable to match `list-group-item`
  padding: 0.6rem 1rem 0.55rem 0.35rem;
  // padding-top: 0.5rem;
  // padding-right: 6px;
  // padding-bottom: 0.5rem;
  // padding-left: 44px; // icon + offset
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  // font-size: 1em;
  align-items: baseline;
  position: relative;
  flex: 1 1 100%;
  // width: 100%;
  // overflow: hidden;

  // .node-title {
  //   display: block;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   vertical-align: bottom;
  //   padding: 0;
  //   padding-right: 0.5em;
  //   line-height: 1.75;
  //   margin: 0 0 0 0; // FIXME:  was 0 0.5rem
  //   flex: 0 1 auto;

  //   &.override-title {
  //     // no distinct styles, just an icon for now
  //   }

  //   & > .override-icon {
  //     cursor: help;
  //     margin: 0 0.75em;
  //     color: theme-abstract-color('pop06');
  //     opacity: 0.6;

  //     &:hover {
  //       opacity: 1.0;
  //     }
  //   }
  // }

  .override-icon {
    cursor: help;
    color: theme-abstract-color('pop06');
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  .node-info {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-out;
    opacity: 1;

    &.visible {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
      transform: translate(8px, 0);
    }

    .badge {
      line-height: 1.25;
    }

    > * {
      flex: 0 1 auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  // .node-tools {
  //   display: flex;
  //   flex-direction: row-reverse;
  //   flex: 1 1 auto;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   transition: opacity 0.25s ease-in-out;
  // }
}

.node {
  // $node-level-multiplier: 38px;
  // $node-left-offset: 32px;

  $node-level-multiplier: 2.15rem;
  $node-left-offset: 1.15rem;

  // STRUCTURE
  &-struct {
    &-branch {
      > .node-content {
        background-color: theme-neutral-color('100');
        color: theme-neutral-color('800');

        > .node-status {
          > .node-title {
            //font-size: 1.08rem;
            font-weight: 600;
          }
        }
      }
    }

    &-leaf {
      > .node-content {
        color: theme-neutral-color('900');

        > .node-status {
          > .node-title {
            //font-size: 1.08rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  // DEPTH
  &-level {
    &-0 {
      > .node-content {
        padding-left: 0;
        background-color: theme-neutral-color('200');
        color: theme-neutral-color('800');

        > .node-status {
          > .node-title {
            // font-size: 1.15rem; // nah
          }
        }
      }
    }

    &-1 {
      > .node-content {
        padding-left: $node-level-multiplier * 1 - $node-left-offset;
      }
    }

    &-2 {
      > .node-content {
        padding-left: $node-level-multiplier * 2 - $node-left-offset;
      }
    }

    &-3 {
      > .node-content {
        padding-left: $node-level-multiplier * 3 - $node-left-offset;
      }
    }

    &-4 {
      > .node-content {
        padding-left: $node-level-multiplier * 4 - $node-left-offset;
      }
    }

    &-5 {
      > .node-content {
        padding-left: $node-level-multiplier * 5 - $node-left-offset;
      }
    }

    &-6 {
      > .node-content {
        padding-left: $node-level-multiplier * 6 - $node-left-offset;
      }
    }

    &-7 {
      > .node-content {
        padding-left: $node-level-multiplier * 7 - $node-left-offset;
      }
    }

    &-8 {
      > .node-content {
        padding-left: $node-level-multiplier * 8 - $node-left-offset;
      }
    }

    &-9 {
      > .node-content {
        padding-left: $node-level-multiplier * 9 - $node-left-offset;
      }
    }
  }

  // NODE TYPE
  &-type {
    &-container {
      > .node-content {
        background-color: theme-neutral-color('100');
        color: theme-neutral-color('800');

        // FIXME: make sticky ... but it's broken right now, so nope
        // position: sticky;
        // top: 0;

        > .node-status {
          > .node-title {
            //font-size: 1.08rem;
            font-weight: 600;
          }
        }
      }
    }

    &-content {
      > .node-content {
        > .node-status {
        }
      }
    }
  }

  // EMPTY (container only)
  &-empty {
    > .node-content {
      > .node-status {
        > .node-icon {
          opacity: 0.6;
        }
      }
    }
  }

  // BINDING (content only)
  &-bound {
    > .node-content {
      > .node-status {
        > .node-icon {
          color: theme-neutral-color('800');
          opacity: 1;
        }
      }
    }
  }

  &-unbound {
    $unbound-text-color: theme-neutral-color('600');

    > .node-content {
      color: $unbound-text-color;

      > .node-status {
        > .node-icon {
          color: theme-neutral-color('800');
          opacity: 0.6;
        }

        > .node-title {
          font-weight: 700;
          opacity: 0.8;
        }
      }
    }
  }

  &-drilldown {
    &.node-struct-branch {
      > .node-content {
        background-color: mix(
          theme-abstract-color('primaryToneLight'),
          theme-neutral-color('100'),
          10%
        );
        color: theme-neutral-color('900');
      }
    }
  }

  // HIGHLIGHTING (search results)
  // FIXME: don't highlight fields like this, penfold's color scheme makes it
  // difficult to read. Use a dot or something similar to call attention instead?
  &-highlight {
    &.node-struct-branch {
      > .node-content {
        // background-color: mix(
        //   theme-abstract-color('primaryToneLight'),
        //   theme-neutral-color('100'),
        //   35%
        // );
        background-color: mix($mark-bg, theme-neutral-color('100'), 50%);
        color: theme-neutral-color('900');
      }
    }

    &.node-struct-leaf {
      > .node-content {
        // background-color: mix(
        //   theme-abstract-color('primaryToneLight'),
        //   theme-neutral-color('000'),
        //   35%
        // );
        background-color: mix($mark-bg, theme-neutral-color('000'), 85%);
        color: theme-neutral-color('900');
      }
    }
  }

  &-active {
    &.node-struct-branch,
    &.node-struct-leaf {
      > .node-content {
        background-color: theme-abstract-color('primary');
        color: theme-neutral-color('000');

        > .node-status {
          > .summary-text > a {
            color: theme-neutral-color('000');
          }

          > input {
            border-color: theme-neutral-color('400');
          }
        }
      }
    }
  }
}
