@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

.alert-dismissible .btn-close {
  top: initial;
}

.alert {
  > .mdi {
    height: 1.5rem;
    line-height: 1;
  }
}
