@use 'sass:string';

// Typefaces (using CDN for now)
// NOTE: REMOVED to switch to best practice for next.js:
// https://nextjs.org/docs/basic-features/font-optimization
//
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&display=swap');
//
// FIXME: This isn't a good idea to load unless using server-side pages.
// It isn't necessary for React-based icons.
// @import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.9.55/css/materialdesignicons.min.css'); // Server-side only dependency

// TODO: color map safe background to foreground colours? theme-level?
// https://medium.com/@MikeKellyWeb/calculating-color-contrast-with-sass-eff39ef23f96
// https://github.com/jhogue/automated-a11y-sass/blob/master/a11y-color.scss

$theme-brand-colors: ();
$theme-brand-colors: map-merge(
  (
    'primaryToneLighter': #71c5e8,
    'primaryToneLight': #00a9e0,
    'primary': #006298,
    'primaryToneDark': #003865,
    'primaryToneDarker': #00263e,
  ),
  $theme-brand-colors
);

$theme-spectrum-colors: ();
$theme-spectrum-colors: map-merge(
  (
    'black': #000,
    'white': #fff,
    'gray': #808080,
    'blue': #1050de,
    'deepblue': #002d8f,
    'indigo': #4a53b8,
    'purple': #92278f,
    'magenta': #e0004d,
    'pink': #f59295,
    'red': #c61d23,
    'orange': #fc4c02,
    'deepyellow': #f2a900,
    'yellow': #ffc72c,
    'lightgreen': #48a200,
    'green': #3a8200,
    'pastelgreen': #91cf60,
    'teal': #007a6d,
    'cyan': #70cdff,
  ),
  $theme-brand-colors
);

// FIXME: Correct definition for linear vs. arbitrary scale,
// i.e. maybe add 50, 75, 150 to below, similar at dark end
$theme-neutral-colors: ();
$theme-neutral-colors: map-merge(
  (
    '000': theme-spectrum-color('white'),
    '050':
      mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 97.5),
    '100': mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 95),
    '150':
      mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 92.5),
    '200': mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 90),
    '250':
      mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 82.5),
    '300': mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 75),
    '400': mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 60),
    '500':
      mix(theme-spectrum-color('white'), theme-spectrum-color('gray'), 46.5),
    '600':
      mix(theme-spectrum-color('black'), theme-spectrum-color('gray'), 6.75),
    '700':
      mix(theme-spectrum-color('black'), theme-spectrum-color('gray'), 33.5),
    '800':
      mix(theme-spectrum-color('black'), theme-spectrum-color('gray'), 56.5),
    '850':
      mix(theme-spectrum-color('black'), theme-spectrum-color('gray'), 64.5),
    '900':
      mix(theme-spectrum-color('black'), theme-spectrum-color('gray'), 73.5),
    '950':
      mix(theme-spectrum-color('black'), theme-spectrum-color('gray'), 85.5),
    '1000': theme-spectrum-color('black'),
  ),
  $theme-neutral-colors
);

$theme-abstract-colors: ();
$theme-abstract-colors: map-merge(
  (
    // Core
    'foreground': theme-neutral-color('800'),
    'background': theme-neutral-color('000'),
    'neutral': theme-spectrum-color('gray'),
    'primary': theme-brand-color('primary'),
    'secondary': theme-neutral-color('800'),
    // Tones
    'lighter': theme-neutral-color('100'),
    'light': theme-neutral-color('200'),
    'lessLight': theme-neutral-color('250'),
    'lessDark': theme-neutral-color('850'),
    'dark': theme-neutral-color('900'),
    'darker': theme-neutral-color('950'),
    'primaryToneDarker': theme-brand-color('primaryToneDarker'),
    'primaryToneDark': theme-brand-color('primaryToneDark'),
    'primaryToneLight': theme-brand-color('primaryToneLight'),
    'primaryToneLighter': theme-brand-color('primaryToneLighter'),
    // Interaction
    'active': theme-brand-color('primary'),
    'hyperlink': theme-spectrum-color('deepblue'),
    'focus': theme-spectrum-color('blue'),
    'focusInverse': rgba(theme-spectrum-color('white'), 0.7),
    'highlight': theme-spectrum-color('yellow'),
    'highlightBlock':
      mix(theme-brand-color('primaryToneLighter'), theme-neutral-color('000')),
    // Feedback
    'info': theme-spectrum-color('blue'),
    'success': theme-spectrum-color('green'),
    'successLight': theme-spectrum-color('pastelgreen'),
    'warning': theme-spectrum-color('deepyellow'),
    'warningLight': theme-spectrum-color('yellow'),
    'danger': theme-spectrum-color('red'),
    'dangerLight': theme-spectrum-color('pink'),
    // Pops
    'pop1': theme-spectrum-color('magenta'),
    'pop2': theme-spectrum-color('orange'),
    'pop3': theme-spectrum-color('deepyellow'),
    'pop4': theme-spectrum-color('yellow'),
    'pop5': theme-spectrum-color('purple'),
    'pop6': theme-spectrum-color('teal')
  ),
  $theme-abstract-colors
);

$theme-font-size: 15px;

$theme-fonts: ();
$theme-fonts: map-merge(
  (
    sans-serif: string.unquote("'Open Sans', system-ui, Helvetica, sans-serif"),
    serif: string.unquote("'Noto Serif', Georgia, sans-serif"),
    monospace:
      string.unquote(
        "'Fira Code', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', monospace"
      ),
  ),
  $theme-fonts
);

// Control focus indicators
$theme-focus-ring-width: 0.35rem;
$theme-focus-ring-inner-width: $theme-focus-ring-width * 0.45;
$theme-focus-box-shadow: 0 0 0 $theme-focus-ring-inner-width
    theme-abstract-color('background'),
  0 0 0 $theme-focus-ring-width theme-abstract-color('focus');
$theme-success-box-shadow: 0 0 0 $theme-focus-ring-inner-width
    theme-abstract-color('background'),
  0 0 0 $theme-focus-ring-width theme-abstract-color('success');
$theme-danger-box-shadow: 0 0 0 $theme-focus-ring-inner-width
    theme-abstract-color('background'),
  0 0 0 $theme-focus-ring-width theme-abstract-color('danger');

// Shadows
$theme-shadow-color: theme-spectrum-color('black');
$theme-popup-box-shadow: 0 0.1rem 0.3rem rgba($theme-shadow-color, 0.18);
$theme-popup-box-shadow-sm: 0 0.125rem 0.25rem rgba($theme-shadow-color, 0.075);
$theme-popup-box-shadow-lg: 0 1rem 3rem rgba($theme-shadow-color, 0.18);
$theme-popup-box-shadow-inset: inset 0 1px 2px rgba($theme-shadow-color, 0.075);

// Local component/override variables

// Typography
$expressive-color: theme-abstract-color('primaryToneDark');
