.body-container {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  // width: 100vw;
  // max-width: 100vw;
}

#penfold-header {
  user-select: none;
  flex: 0;
}

#penfold-announce {
  flex: 0;
}

/*
#penfold-app-scope {
  flex: 1;
  user-select: none;
  display: flex;
  //max-height: 365px; // FIXME: this is the important one ...
  flex-direction: row;

  & > * {
    flex: 0;
    display: flex;
    flex-direction: column;
  }


  #penfold-app-nav {

  }

  #penfold-app-main {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    max-height: inherit;
    overflow: hidden;
  }

  #penfold-app-nav > .menu-toggle,
  #penfold-app-header {
    //height: 58px;
    vertical-align: middle;
    flex: 0;
  }

  #penfold-app-nav-sub {
    flex: 0;
  }

  #penfold-app-instruct {
    flex: 0;
  }

  #penfold-app-body {
    flex: 1 1 100%;
    max-height: inherit;
    user-select: text;

    // overflow: auto;
  }
}
*/

#penfold-app-header {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  #penfold-app-title {
    flex: 1 1 100%;
  }

  #penfold-app-toolbar {
    flex: 1 1 auto;
    justify-content: flex-end;
  }
}

#penfold-footer {
  flex: 0 0 auto;
}

// mobile
@media (max-width: map_get($grid-breakpoints, 'lg') - 1px) {
  #penfold-app {
    // overflow-x: hidden; // FIXME: shouldn't need to hide any overflow on mobile
  }

  .body-container {
  }
}

// desktop
@media (min-width: map_get($grid-breakpoints, 'lg')) {
  #penfold-app {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
    max-height: 100vh;

    // FIXME: huh? This breaks scrolling ... removing for now
    //& > * {
    //  flex: 0 0 auto;
    //}
  }

  .body-container {
    height: 100vh;
    // overflow-x: hidden; // use this instead to make whole page scroll vertically
    overflow: hidden;
    max-height: 100vh;
  }
}
