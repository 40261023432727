.rich-text {
  img,
  iframe,
  embed {
    max-width: 100%;
    height: auto;
  }
}

.responsive-object {
  position: relative;

  img {
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
  }

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
