@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

$margin-text-x: 1.5rem;
$max-text-width: 48rem;
$blockquote-bordercolor: theme-abstract-color('primaryTintLight');
$blockquote-bgcolor: RGBA(theme-abstract-color('primaryTintLighter'), 0.25);
$blockquote-textcolor: theme-abstract-color('primaryTintDarker');
$footnote-bgcolor: RGBA(theme-neutral-color('004'), 0.5);
$hr-bordercolor: theme-abstract-color('005');
$heading-color: theme-neutral-color('800');
$subheading-color: theme-neutral-color('800');

// Wrapper and general styles
.docs-wrapper {
  // position: relative; // This messes with ability to fullscreen drawings
  padding-bottom: 0.1px; // FIXME: trigger different layout mode?
}

.docs-page {
  // min-width: 24em;
  // max-width: 52em;

  .docs {
    // padding: 1rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    // margin-right: 16px;
    // margin-bottom: 24px;

    & > p,
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6,
    & > blockquote,
    & > .docs-heading,
    & > .lead,
    & > .rich-text,
    & > .docs-heading {
      max-width: $max-text-width;
      padding-left: $margin-text-x;
      padding-right: $margin-text-x;
    }

    & > hr {
      width: 100%;
    }

    & > .lead {
      line-height: 1.75;
    }

    & > dl,
    & > ol,
    & > ul {
      max-width: #{$max-text-width - $margin-text-x * 2};
    }
  }
}

.docs-inline {
  .docs {
    overflow: auto;
    padding: 0;

    & > *:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.docs {
  // overflow: auto;
  & > .lead > p,
  & > p,
  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    margin: 1rem 0;
  }

  & > dl,
  & > ol,
  & > ul,
  & > blockquote {
    margin: 1rem $margin-text-x;
  }

  & > blockquote {
    margin: 0 $margin-text-x;
    padding: 0.75rem 1.5rem;
    border-left: 12px solid $blockquote-bordercolor;
    background-color: $blockquote-bgcolor;
    color: $blockquote-textcolor;
    font-style: italic;

    p,
    h1,
    h2,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  & > .footnotes {
    background-color: $footnote-bgcolor;

    max-width: #{$max-text-width};

    hr {
      margin: 0;
      padding: 0;
      border-style: solid none none;
      border-width: 2px;
      border-color: $hr-bordercolor;
    }

    ol {
      padding-left: 4em;
      padding-right: 2em;
      padding-bottom: 1em;
    }

    .footnote-backref {
      display: inline-block;
      padding: 0 0.5rem;
    }
  }
}

// Heading
.docs-heading {
  position: relative;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: 2.4rem;
    font-weight: 600;
    margin: 0 -0.065em; // compensate for size disparity
    padding: 0;
    color: $heading-color;
  }

  p {
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    color: $subheading-color;
    letter-spacing: 0.07em;
  }
}

@include media-breakpoint-up(sm) {
  .docs-heading {
    align-items: center;
    flex-direction: row;
  }
}
