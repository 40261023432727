@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

$toolbar-bgcolor: mix(theme-neutral-color('200'), theme-neutral-color('100'), 50%);
$toolbar-border-color: theme-neutral-color('200');

.app-toolbar {
  padding: 0.25rem;
  //align-self: center;
  // padding: 10px 0;
  // white-space: nowrap;
  // flex-wrap: nowrap;
  // flex: 1 1 auto;

  & > * {
    margin: 0.25rem;
  }

  &:empty {
    display: none;
  }
}

.view-toolbar {
  // padding: 0.25rem;
  // white-space: nowrap;
  background-color: $toolbar-bgcolor;
  // border-bottom: 2px solid $toolbar-border-color; // use bs utilities instead
  // border-top-left-radius: 4px; // Use bs utilities instead
  // border-top-right-radius: 4px; // use bs utilities instead
  // position: relative;

  // & > * {
  //   margin: 0.25rem;
  // }

  &:empty {
    display: none;
  }
}
