@use 'sass:math';

@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';
@import './bootstrap-variables';

// TODO: Move me to be with the component(s) defined?
// TODO: Move me to become a CSS module!

// colors
$bgcolor_headings: theme-neutral-color('100');
$unsortable_headings: theme-neutral-color('800');
$sortable_headings: theme-neutral-color('900');
$sortable_headings_hover: theme-neutral-color('900');
$sortable_headings_hover_bgcolor: theme-neutral-color('300');
$sort_indicator: theme-neutral-color('700');
$sort_indicator_next: theme-abstract-color('primary');
$table_border_color: theme-neutral-color('400');
$table_dark_color: theme-neutral-color('100');
$table_dark_input_border_color: theme-neutral-color('200');
$table_dark_bgcolor: theme-abstract-color('primaryToneDarker');
$table_dark_border_color: mix($table_dark_bgcolor, $white, 60%);

// distances
$cell_line_height: $line-height-base;
$cell_padding: $table-cell-padding-y $table-cell-padding-x;
$cell_padding_sm: $table-cell-padding-y-sm $table-cell-padding-x-sm;
$cell_height: $cell_line_height + ($table-cell-padding-y * 2);
$heading_padding: calc(#{$cell_padding} - #{$input-btn-focus-width});

.list-container,
.table-container {
  // overflow-y: auto; // FIXME: This makes menus unusable at lower resolution
  // order: 10;
  //margin-bottom: 1rem;
  //border-top: 1px solid $table-border-color;
  //border-bottom: 1px solid $table-border-color;

  // + .recordset-metadata,
  // + .table-metadata,
  // + .list-metadata {
  //   border-top: 1px solid $table_border_color;
  // }
}

.table {
  margin-bottom: 0;

  // Can enhance performance (at higher setup/config cost?)
  &.fixed {
    table-layout: fixed;
  }

  // Remove something awful in Bootstrap 5.1
  > :not(:first-child) {
    border-top: none;
  }

  thead,
  .thead-light {
    th {
      // font-weight: 600;
      // padding-top: 4px;
      // padding-bottom: 4px;
      // padding-left: $cell_padding;
      // padding-right: $cell_padding;
      // padding: $cell_padding;
      // color: $unsortable_headings;
      // background-color: $bgcolor_headings;
      // box-shadow: inset 0 -2px $table_border_color;
      // height: 2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: baseline;
      // overflow: hidden; // FIXME: can't show popups now?
      // OVERFLOW should be managed on per-column basis, not like this
      z-index: 1; // FIXME: z-index causes problems for popups; override?

      // Sticky headings require box-shadow to create a border
      // position: sticky;

      border-style: none;
      top: 0;
      border-bottom: 0;

      // SORTING
      &.orderable {
        padding: 0;

        & > a {
          $outline_offset: -2px; // fixme: outline width + outline offset

          @include transition($btn-transition);

          text-decoration: none;
          outline-offset: $outline_offset;
          color: $sortable_headings;
          font-weight: 700;
          display: block;
          position: relative;
          margin: 0;

          &:after {
            vertical-align: -5%;
            text-align: left;
            line-height: inherit;
            width: 1.35em;
            //height: 0.9em;
            height: 1em;
            color: $sort_indicator;
            display: inline-block;
            margin-left: 0.5em;
            background-size: 0.95rem;
            background-position: bottom center;
            background-repeat: no-repeat;
            background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 40 40" class="icon" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M9.83821077,0.544385798 C10.5640585,-0.181461933 11.7406567,-0.181461933 12.4665045,0.544385798 L12.4665045,0.544385798 L21.7603294,9.83821077 C22.4861772,10.5640585 22.4861772,11.7406567 21.7603294,12.4665045 C21.3978703,12.829893 20.9220264,13.0111226 20.4461826,13.0111226 C19.9703388,13.0111226 19.4944949,12.8289636 19.1320357,12.4665045 L19.1320357,12.4665045 L13.0111226,6.34559135 L13.0111226,37.6954009 C13.0111226,38.7223686 12.1793253,39.5541659 11.1523576,39.5541659 C10.12539,39.5541659 9.29359263,38.7223686 9.29359263,37.6954009 L9.29359263,37.6954009 L9.29359263,6.34559135 L3.1726795,12.4665045 C2.44683177,13.1923522 1.27023353,13.1923522 0.544385798,12.4665045 C-0.181461933,11.7406567 -0.181461933,10.5640585 0.544385798,9.83821077 L0.544385798,9.83821077 Z M28.8476424,0 C29.87461,0 30.7064074,0.831797335 30.7064074,1.85876499 L30.7064074,33.2085746 L36.8273205,27.0876614 C37.1897797,26.7252023 37.6656235,26.5430433 38.1414674,26.5430433 C38.6173112,26.5430433 39.093155,26.7242729 39.4556142,27.0876614 C40.1814619,27.8135092 40.1814619,28.9901074 39.4556142,29.7159552 L30.1617892,39.0097801 C29.4359415,39.7356279 28.2593433,39.7356279 27.5334955,39.0097801 L18.2396706,29.7159552 C17.5138228,28.9901074 17.5138228,27.8135092 18.2396706,27.0876614 C18.9655183,26.3618137 20.1421165,26.3618137 20.8679643,27.0876614 L26.9888774,33.2085746 L26.9888774,1.85876499 C26.9888774,0.831797335 27.8206747,0 28.8476424,0 Z"></path></svg>');
            opacity: 0.45;
            content: ' ';
          }

          &:hover {
            text-decoration: none;
            color: $sortable_headings_hover;
            background-color: $sortable_headings_hover_bgcolor;
          }

          &:hover:after {
            color: $sort_indicator_next;
            opacity: 0.7;
          }

          &:focus {
            outline-offset: $outline_offset;
          }
        }
      }

      &.asc > a:after {
        // background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 21 24.5" class="icon" height="14" width="14" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M24,11.1,15.24,2.35a1.74,1.74,0,0,0-2.48,0L4,11.1a1.75,1.75,0,1,0,2.48,2.47l5.76-5.76V24.58a1.75,1.75,0,0,0,3.5,0V7.81l5.76,5.76a1.76,1.76,0,0,0,1.24.52A1.72,1.72,0,0,0,24,13.57,1.75,1.75,0,0,0,24,11.1Z" transform="translate(-3.5 -1.84)"></path></svg>');
        background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" class="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="32" width="32"><path d="M5.71 9.7c.39.39 1.02.39 1.41 0L11 5.83V21c0 .55.45 1 1 1s1-.45 1-1V5.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 2.7a.9959.9959 0 00-1.41 0L5.71 8.29c-.39.39-.39 1.03 0 1.41z" fill-rule="evenodd"></path></svg>');
        background-size: 1.1rem;
        vertical-align: -10%;
        opacity: 0.7;
      }

      &.desc > a:after {
        // background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 21 24.5" class="icon" height="14" width="14" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M24,16.9l-8.75,8.75a1.74,1.74,0,0,1-2.48,0L4,16.9a1.75,1.75,0,1,1,2.48-2.47l5.76,5.76V3.42a1.75,1.75,0,0,1,3.5,0V20.19l5.76-5.76a1.76,1.76,0,0,1,1.24-.52,1.72,1.72,0,0,1,1.24.52A1.75,1.75,0,0,1,24,16.9Z" transform="translate(-3.5 -1.67)"></path></svg>');
        background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" class="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="32" width="32"><path d="M18.3 14.29a.9959.9959 0 00-1.41 0L13 18.17V3c0-.55-.45-1-1-1s-1 .45-1 1v15.18L7.12 14.3a.9959.9959 0 00-1.41 0c-.39.39-.39 1.02 0 1.41l5.59 5.59c.39.39 1.02.39 1.41 0l5.59-5.59c.38-.39.38-1.03 0-1.42z" fill-rule="evenodd"></path></svg>');
        background-size: 1.1rem;
        vertical-align: -10%;
        opacity: 0.7;
      }

      // TYPE declarations
      &.type {
        &__checkbox,
        &__radio {
          $size: 3.2em;
          min-width: $size;
          max-width: $size;
          width: $size;
          text-align: center;

          // .custom-control-input {
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   right: 0;
          //   bottom: 0;
          //   width: 100%;
          //   height: 100%;
          //   z-index: 1;
          // }
        }

        &__switch {
          $size: 4.2em;
          min-width: $size;
          max-width: $size;
          width: $size;
        }

        &__icon,
        &__action {
          $icon_col_width: 3em;

          min-width: $icon_col_width;
          max-width: $icon_col_width;
          width: $icon_col_width;
          text-align: center;
          overflow: visible;
        }

        &__action {
          padding: 0 !important;
        }

        &__field {
          min-width: 8em;
          width: auto;
        }

        &__title {
          min-width: 8em;
          // max-width: 32em;
          // max-width: 16em;

          // width: 30%;
        }

        &__uuid {
          // $charlen: 36; // rationale: 36 characters
          // $emwidth: 0.4rem * $charlen;
          // min-width: 8em;
          // max-width: 16em;
          // max-width: $emwidth;
          //width: 30%;
        }

        &__cgi {
          min-width: 8em;
          max-width: 10em;
          width: 13em;

          //width: 20%;
        }

        &__date {
          width: 7em;
        }

        // TODO: handle diff. locales?
        &__datetime {
          $datetime_col_width: 11.5em;

          min-width: $datetime_col_width;
          max-width: $datetime_col_width;
          width: $datetime_col_width;
        }
      }

      // field NAMES, i.e. `workspace_id`; these belong in TEMPLATES not here
      &.name {
        &__created_by {
          min-width: 6em; // This belongs to the column/field
          max-width: 12em;
        }
      }
    }
  }

  tbody {
    tr:first-child td {
      border-top-style: none;
    }

    //td:first-child {
    //  padding-left: 1.5rem;
    //}
    //
    //td:last-child {
    //  padding-right: 1.5rem;
    //}

    th,
    td {
      // padding: $cell_padding;
      // line-height: $cell_line_height;
      // height: $cell_height;
      // max-width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: baseline;
      // IMPORTANT: do NOT hide overflow on th/td; it HIDES MENUS

      // TODO: Move dtype declarations out of here
      &.type {
        &__icon {
          // nothing yet ...
          text-align: center;
        }

        &__title {
          min-width: 8em;
          max-width: 16em;
          overflow: hidden;
        }

        &__action {
          padding: 0 !important;
          text-align: center;
          vertical-align: middle;
        }

        &__ip {
          text-align: right;
          overflow: hidden;
        }

        &__ip,
        &__cgi,
        &__uuid {
          // FIXME: this doesn't want to load
          $size-multiplier: 0.9;

          font-size: #{$size-multiplier}rem;
          font-family: monospace;
          user-select: all;
          overflow: hidden;
        }

        &__uuid {
          // FIXME: width definition should be shared between thead/tbody
          $charlen: 36; // rationale: 36 characters
          $emwidth: 0.4rem * $charlen;
          max-width: $emwidth;
        }

        &__int,
        &__float {
          // width: 2em;
          min-width: 2em;
          max-width: 5em;
          text-align: right;
          overflow: hidden;
        }

        &__checkbox,
        &__radio,
        &__switch {
          width: 3.5em;
          min-width: 2em;
          max-width: 4em;
          text-align: center;
        }
      }

      // Embolden hyperlinks for the 'title' column
      &[scope='row'] > a {
        font-weight: 600;
      }
    }
  }

  .table-primary,
  .table-secondary,
  .table-success,
  .table-danger,
  .table-dark {
    th > a,
    td > a {
      color: $white;
    }

    th > input,
    td > input {
      border-color: $table_dark_input_border_color;
    }
  }

  &:not(.table-dark) {
    thead,
    .thead-light {
      th {
        background-color: $bgcolor_headings;
        box-shadow: inset 0 -2px $table_border_color; // border effect
      }
    }
  }

  &.table-dark {
    border-color: $table_dark_border_color;

    thead,
    .thead-light {
      th {
        box-shadow: inset 0 -2px $table_dark_border_color; // border effect
      }

      a {
        color: white;
      }
    }
  }

  &:not(.table-sm) {
    th.orderable a {
      padding: $cell_padding;
    }
  }

  &.table-sm {
    th.orderable a {
      padding: $cell_padding_sm;
    }
  }
}

// mobile
@media (max-width: map_get($grid-breakpoints, 'lg') - 1px) {
  .table-container {
  }

  .pagination-wrapper {
  }

  .summary-wrapper {
  }
}

// desktop
@media (min-width: map_get($grid-breakpoints, 'lg')) {
  .table-container {
    min-height: 15rem; // FIXME: This should be only for responsive desktop sizes
    flex: 1 1 100%;
  }

  // FIXME: Is this the best place to define this?
  .pagination-wrapper {
    padding: 0.5rem;
  }

  .summary-wrapper {
  }
}
