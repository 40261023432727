@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

// FIXME: refactor out
#penfold-footer {
  $footer-bgcolor: mix(
    theme-neutral-color('100'),
    theme-neutral-color('200'),
    75%
  );

  padding: 10px 12px;
  background-color: $footer-bgcolor;
  color: theme-neutral-color('800');
  font-size: 0.85rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
}
