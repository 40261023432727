@import '../../../node_modules/bootstrap5/scss/functions';
@import './theme-functions';
@import './theme-variables';

// FIXME: Needs a rewrite to follow bootstrap conventions more closely

$shadow-color: rgb(0, 0, 0);
$highlight-color: rgb(255, 255, 255);
$header-bgcolor: theme-abstact-color('primaryToneDark');
$header-bggradientcolor: $shadow-color;
$header-bghovercolor: theme-abstract-color('primaryToneDarker');
$header-fgcolor: theme-neutral-color('000');
$header-logosize: 34px;
$tx-duration: 250ms;
$tx-timing-func: ease-in-out;
$closed-width: var(--closed-width, 64px);
$open-width: var(--open-width, 240px);

.platform-header.navbar {
  z-index: 2;
  user-select: none;
  position: relative;
  margin: 0;
  padding: 0;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 4px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  & > .container,
  & > .container-fluid {
    padding: 2px 0;

    & > .navbar-collapse {
      padding-right: 10px;
    }
  }

  .navbar-brand {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.935rem;
    text-shadow: 1px 1px 0.85px rgba($shadow-color, 0.5);
    padding: 5px 10px 5px 10px;
    margin: 0;
    display: flex;
    flex-direction: row;
    transition: min-width $tx-duration, max-width $tx-duration, opacity $tx-duration;
    transition-timing-function: $tx-timing-func;
    min-width: $header-logosize;
    max-width: $closed-width; // Note: MUST sync with brandExpanded.max-width

    // FIXME: This positioning solution needs to be reworked to allow easy
    // rebranding/franchising
    .brand-logo {
      margin: -6px 7px -5px 5px;
      filter: drop-shadow(1px 1px 0.85px rgba($shadow-color, 0.5));
      width: $header-logosize;
      height: auto;
      flex: 0 0 $header-logosize;
    }

    .site-title {
      padding: 0;
      opacity: 0;
      display: inline-block;
      overflow: hidden;
      flex: 1 1 100%;
      max-width: 0;
      transition: min-width $tx-duration, max-width $tx-duration, opacity $tx-duration;
      transition-timing-function: $tx-timing-func;
      box-sizing: border-box;
    }

    &.brand-expanded {
      min-width: $open-width;
      max-width: $open-width;

      .site-title {
        opacity: 1;
        max-width: calc(#{$open-width} - #{$closed-width});
      }
    }
  }

  .navbar-nav {
    font-size: 1.1rem;

    > .navbar-separator {
      $separator-width: 2px;

      display: block;
      background: rgba($highlight-color, 0.18);
      transition: min-width $tx-duration, max-width $tx-duration, opacity $tx-duration;
      transition-timing-function: $tx-timing-func;
      justify-self: flex-end;
      margin-right: auto !important;

      @include media-breakpoint-down(sm) {
        height: $separator-width;
        width: 100%;
        margin: 15px 0;
      }

      @include media-breakpoint-up(md) {
        width: $separator-width;
        height: 30px;
        margin: 8px 10px;
      }

      &:first-of-type {
        @include media-breakpoint-up(md) {
          margin-left: 0 - $separator-width;
        }
      }
    }

    > .nav-breadcrumb {
      flex: 1 1 auto;

      > .breadcrumb {
        background-color: transparent;
        margin: 6px 0;
        padding: 6px 1em;
        font-size: 90%;

        .breadcrumb-item {
          color: theme-neutral-color('500');

          .active {
            color: theme-abstract-color('primaryToneLight');
          }

          > a {
            color: theme-neutral-colors('200');
            text-decoration: underline;
            text-underline-offset: 2px;

            // &:focus {
            //   outline-color: map-get($theme-abstact-colors, focusInverse);
            //   outline-offset: 2px;
            // }
          }
        }
      }
    }

    > .nav-notify {
      margin: 0;
      padding: 0;
    }

    > .nav-item > .nav-link {
      margin: 4px 4px;
      padding: 4px 6px;
      color: theme-neutral-color('000');
    }

    .nav-item {
      text-transform: none;
    }

    .nav-link {
      text-transform: none;
    }

    .nav-link-active {
      text-transform: none;
    }

    .btn-link {
      &.btn-circle {
        width: 38px;
        height: 38px;
        padding: 7px;
        margin: 5px 4px;
        background-color: rgba(0, 0, 0, 0); // FIXME
        line-height: 1.6;
        border-style: none;

        &:hover {
          // FIXME: can't use a variable here
          background-color: rgba($header-bghovercolor, 0.85);
        }
      }
    }

    .user-icon {
      font-size: 1.25em;
    }

    .dropdown-menu {
      box-shadow: 1px 0 2px rgba($shadow-color, 0.25); // FIXME
    }
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.7rem;

    &::before {
      color: rgba(theme-neutral-color('500'), 0.9);
      // FIXME
      font-family: FontAwesome;
      content: '\f054';
      font-size: 65%;
      padding-right: 0.55rem;
    }
  }
}
